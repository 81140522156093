<!-- BackgroundComponent.vue -->
<template>
  <div style="width: 100%;">
    <el-card class="custom-card" body-style="padding:0;">
      <el-row style="height: 40rem;">
        <el-col :span="8" style="background-color: rgba(27, 43, 64, 0.2);">
          <div class="left-align" style="padding-left: 5rem; padding-top: 2.5rem;">
            <h1 class="selectable" @click="redirect('/education')">Education</h1>
            <h1 class="selectable" @click="redirect('/training')">Training</h1>
            <h1 class="selectable" @click="redirect('/employment')">Employment</h1>
            <h3 style="margin: 4rem 0;">Start Your Dream In Australia</h3>
            <button class="btn button-23" @click="redirect('/contact')">CONTACT US</button>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script lang="ts" setup>
  import { useRoute, useRouter } from 'vue-router';
  const router = useRouter();
  const redirect = (path: string) => {
    router.push(path);
  }
</script>

<style>
  .button-23 {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: 2px solid #D43941;
    border-radius: 8px;
    box-sizing: border-box;
    color: #D43941;
    cursor: pointer;
    display: inline-block;
    font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 13px;
    margin: 0;
    outline: none;
    padding: 13px 23px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
  }

  .button-23:focus-visible {
      box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
      transition: box-shadow .2s;
  }

  .button-23:active {
      background-color: #fffffe;
      border-color: #232946;
      color: #232946;
      transform: scale(.96);
  }
</style>

<style scoped>
  .selectable {
    width: 15rem;
  }
  .selectable:hover {
    color: #eebbc3;
    text-decoration: underline;
    cursor: pointer;
  }
  .btn {
    font-weight: 800;
    padding: 10px 16px;
    font-size: 13px;
    letter-spacing: 2px;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .left-align {
    text-align: left;
  }
  h3 {
    color:#fffffe;
    font-size: 1.5rem;
    font-weight: 400;
  }
  h1 {
    color:#fffffe;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .custom-card {
    background-image: url('@/assets/background1.jpg');
    background-size: cover !important;
    background-position: center;
    color: #fffffe;
    height: 40rem;
  }
</style>