<template>
    <div>
        <h5 class="line-with-text">
        <el-divider class="divider" border-style="double" />
        Login Form
        <el-divider class="divider" border-style="double" />
        </h5>
        <el-form 
        label-position="left"
        :model="form"
        label-width="120px"
        >
        <el-form-item label="Username">
            <el-input v-model="form.username" placeholder="Please input username"/>
        </el-form-item>
        <el-form-item label="Password">
            <el-input v-model="form.password" type="password" placeholder="Please input password" show-password/>
        </el-form-item>
        <el-form-item>
            <el-button color="#232946" @click="onSubmit">Submit</el-button>
            <!-- <el-button color="#d4939d" plain>Cancel</el-button> -->
        </el-form-item>
        </el-form>
    </div>

</template>
  
<script lang="ts" setup>
  import { inject, defineProps, reactive } from 'vue'
  import type { UploadRawFile, UploadRequestOptions } from 'element-plus'
  import { ElNotification as notify, useGetDerivedNamespace } from 'element-plus'
  import { useRoute, useRouter } from 'vue-router'
  import axios from 'axios';

  const router = useRouter();
  const $axios : any = inject('axios');

  const fileList: UploadRawFile[] = [];
  
  // do not use same name with ref
  const form = reactive({
    username: '',
    password: ''
  });
  
  const onSubmit = () => {
    $axios.post(`/login?username=${form.username}&password=${form.password}`).then((res: any) => {
      if (res.data.code === 200) {
        window.location.reload();
      }
      else {
        notify('Incorrect Credentials')
      }
    });
    // window.location.href = "https://www.google.com";
  }
</script>

<style>
  .el-form-item__label {
    font-family: Lato, sans-serif;
  }
</style>

<style scoped>
  .line-with-text {
      display: flex;
      align-items: center;
  }

  .divider {
      flex: 1;
      margin: 0 20px;
  }
</style>
  