<template>
  <div class="employment">
      <h1>EMPLOYMENT</h1>
      <div class="flex">
        <div style="flex: 2;" />
        <div style="flex: 8;">
          <el-page-header @back="onBack"></el-page-header>
        </div>
        <div style="flex: 11;"></div>
      </div>
      
      <div class="flex">

        <div style="flex: 2;" />

        <div style="flex: 17;">
          <CardText :value="data.paragraph"></CardText>
        </div>

        <div style="flex: 2;" />
      </div>
      
      <br>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import CardText from '@/components/CardText.vue'

  const router = useRouter();
  const onBack = () => {
    router.back();
    // notify('Back')
  }

  const paragraph = [
    {
      title:'Skill Shortage Assistance',
      data:'1.	Assist Employers experiencing Skills Shortage in Australia to recruited Skill labour overseas under Labour Hire Agreement and Visa 482 or Visa 494 together with our Migration Partners.<br><br>2.	Assist Australian Employers who would like to recruit in the Philippines through POEA Accredited Agents.'
    }
  ];

  const data = reactive(
    {
      paragraph:paragraph
    }
  );
</script>

<style scoped>
  h1 {
    font-size: 2.5rem;
    font-weight: 400;
  }
</style>