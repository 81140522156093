<template>
    <!-- <HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/> -->
    <!-- <Menu></Menu>
    <RouterView></RouterView> -->
    <!-- <Scroll></Scroll> -->
  
  <el-container class="layout-container-demo">
    
    <!-- <el-container style="height: 100vh;"></el-container> -->
    <el-container>
      <el-header style="height: 6rem; width: 100%;">
          <WapMenu></WapMenu>
      </el-header>

      <el-main style="height: 100vh;">
        <el-scrollbar ref="scrollbarRef">
          <RouterView></RouterView>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>
  
  <script lang="ts" setup>
    import { watch, provide, createApp, onMounted, onBeforeMount, ref } from 'vue'
    import WapMenu from '@/components/wap/Menu-wap.vue'
    import axios from 'axios'
    import { useRoute, useRouter } from 'vue-router'

    const route = useRoute();
    const router = useRouter();
  
    axios.defaults.baseURL = '/api';
  
    provide('axios', axios);
  
    const scrollbarRef = ref(null);
    const resetScrollbar = () => {
      if (scrollbarRef.value) {
        // Reset the scrollbar's scroll position to the top
        scrollbarRef.value.scrollTo(0, 0);
      }
    }
    const redirectWap = () => {
      const path = route.path;
      if (path.indexOf('-wap') < 0) {
        router.push(`${path}-wap`);
      }
    }
    watch(
      () => route.path,
      () => {
        resetScrollbar();
        redirectWap();
      }
    );

  
    const debounce = (callback: (...args: any[]) => void, delay: number) => {
      let tid: any;
      return function (...args: any[]) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };
  
    const _ = (window as any).ResizeObserver;
    (window as any).ResizeObserver = class ResizeObserver extends _ {
      constructor(callback: (...args: any[]) => void) {
        callback = debounce(callback, 20);
        super(callback);
      }
    };
    
    const height = ref("");
    onBeforeMount(() => {
      const h = `${document.documentElement.clientHeight}`;
      height.value = Math.trunc(Number(h)/16 - 6) + "rem";
      // console.log(height);
    });
  
    const handleResize = () => {
      const h = `${document.documentElement.clientHeight}`;
      height.value = Math.trunc(Number(h)/16 - 6) + "rem";
    }
  
    onMounted(() => {
      window.addEventListener('resize', handleResize);

    });
  
  </script>
  
  <style>
    @import url(https://fonts.googleapis.com/css?family=Lato:400,700,300);
    body {
      color: #232946;
      background: #f5f5f5;
      font-family: Lato, sans-serif;
      font-weight: 300;
      line-height: 1.6;
      font-size: 1rem;
    }
  
    span {
      /* color: #232946; */
      font-family: Lato, sans-serif;
      font-weight: 400;
    }
  
    /* h1 {
      margin: 1rem 0;
    } */
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #232946;
        font-family: Lato, sans-serif;
        font-weight: 700;
    }
    
    .flex {
      display: flex;
    }
  </style>
  
  <style scoped>
    .layout-container-demo .el-header {
        padding: 0;
    }
    .layout-container-demo .el-main {
        padding: 0;
    }
  </style>
  
  <style lang="scss">
    #app {
      font-family: Lato, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #232946;
    }
  </style>
  