<template>
    <div class="training">
        <h1>TRAINEESHIP</h1>
        <CardImg :value="training"></CardImg>
    </div>
</template>

<script lang="ts" setup>
  import { reactive } from 'vue';
  import CardImg from '@/components/wap/CardImg-wap.vue';

  const training = reactive([
      {
        id:0, 
        title:'Occupational Traineeship', 
        img:'training-brief.jpg', 
        route:'/training-brief-wap', 
        desc:'Brief introduction of the Occupational Traineeship'
      },
      {
        id:1, 
        title:'Occupational Traineeship for Nurses', 
        img:'nurse.jpg', 
        route:'/nurse-wap', 
        desc:'The latest information about nurse traineeship'
      },
      {
        id:2, 
        title:'Occupational Traineeship for Cooks', 
        img:'cook.jpg', 
        route:'/cook-wap', 
        desc:'The latest information about cook traineeship'
      }
  ]);
</script>

<style scoped>
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
</style>