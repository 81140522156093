<template>
    <div class="cook">
        <h1>OCCUPATIONAL TRAINEESHIP FOR NURSES</h1>
        <PageHeader :value="data"></PageHeader>
        
        
        <br>
    </div>
</template>
<script lang="ts" setup>
    import { inject, onBeforeMount, ref, reactive } from 'vue'
    import PageHeader from '@/components/PageHeader.vue';
    

    const $axios : any = inject('axios');

    const table = ref([]);

    // onBeforeMount(() => {
    //   $axios.get('/courses/all').then((res: any) => {
    //     table.value = res.data;
    //   })
    // });

    const img = [
      {
        title:'Upcoming FREE Information Seminars in the Philippines',
        data:[
          {
            desc:'Baguio City&nbsp&nbsp&nbsp16 January 2024',
            src:'All - FLYER NURSING. BAGUIO.png'
          },
          {
            desc:'Zamboangs City&nbsp&nbsp&nbsp20 January 2024',
            src:'All - FLYER  NURSING. ZAMB.png'
          }
        ]
      }
    ];

    const paragraph = [
      {
        title:'Introduction',
        data:'As a discipline and profession, nursing incorporates frameworks and theories that are grounded in the totality and simultaneity paradigms that focus on theory and professional nursing practice, underpinned by philosophies of person-centred approach and holistic care (Giuliano, Tyer-Viola, & Lopez, 2005).<br><br>OBA REVIEW FOR IQN builds on previous knowledge, skills, and experience. Participants are orientated to contemporary nursing practice in Australia in preparation for NLCLEX – RN Examination and the OSCE for IQN.'
      },
      {
        title:'Aim',
        data:'To prepare internationally qualified nurses, who are required by the Nursing and Midwifery Board of Australia (NMBA) as part of the Australian Health Practitioners Regulation Authority (AHPRA), to undertake an Outcome Based Assessment as part of the process to acquire apply for Australian Registration.<br><br>This Outcome Based Assessment (OBA) Review Program is designed to provide Internationally Qualified Nurses (IQN) contemporary information about healthcare systems and nursing care practices in Australia. The aim of this program is to prepare  IQN for the AHPRA (Australian Healthcare Practitioner Regulation Agency (AHPRA) new assessment model for Nurses Registration.<br><br>The OBA is a two-step assessment process. OBA comprises a multiple-choice (MCQ) exam and an objective structured clinical exam. Overseas Registered Nurses must pass the first assessment before moving onto the second.<br><br>Part one is a cognitive assessment (MCQ Exam), which is a computer-based multiple-choice exam. IQN must pass the computer-based exam called NCLEX-RB to move on to part two of the OBA.<br><br>Part two is a behavioural assessment in the form of an objective structured clinical examination (OSCE). The OSCE is designed to assess your ability to competently apply your professional nursing skills and knowledge in Australia.<br><br>The OSCE will simulate a clinical environment and “patient” scenarios which Registered Nurses are likely to encounter when they assess, plan, implement and evaluate care.'
      },
      {
        title:'Key Objectives',
        data:'&nbsp&nbsp&nbsp1.&nbsp&nbsp&nbspEnable participants to be adequately prepared for successful completion of the Outcome Based Assessment for Australian Registered Nurses<br><br>&nbsp&nbsp&nbsp2.&nbsp&nbsp&nbspEnable overseas registered nurses to attend a supervised and supported clinical placement in an Australian healthcare setting pre OSCE assessment<br><br>&nbsp&nbsp&nbsp3.&nbsp&nbsp&nbspEnable newly registered qualified registered nurses to attend a supervised and supported clinical placement in an Australian Healthcare settings'
      },
      // {
      //   data:'Zamboangs City<br>20 January 2024',
      //   src:'Nurses Campaign - Baguio.jpg'
      // }
    ];

    const data = reactive(
      {
        paragraph:paragraph,
        table:table,
        img:img,
        rootPath:'/training',
        root:'Training',
        current:'nurse training',
        currentPath:'/nurse',
        inquiryPath:'/contact/training',
        isPara:true,
        isTable:true,
        isCarousel:true
      }
    );

</script>

<style scoped>
  h1 {
    font-size: 2.5rem;
    font-weight: 400;
  }
</style>