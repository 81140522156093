<template>
    <div v-for="p in paragraph.value">
    <h3 class="line-with-text">
        <el-divider class="divider" border-style="double" />
        {{ p.title }}
        <el-divider class="divider" border-style="double" />
    </h3>
    <!-- max-height="750" -->
    <el-scrollbar 
    :style="{ boxShadow: `var(--el-box-shadow-lighter)`}" 
    >
        <el-card 
        class="box-card" 
        :style="{ boxShadow: `var(--el-box-shadow-lighter)`}" 
        body-style="padding:1rem; color:#000073; font-family: Lato, sans-serif; text-align:left;"
        >
            <div v-if="(typeof paragraph.click == 'string')" v-html="p.data" @click="redirect(paragraph.click)"></div>
            <div v-else v-html="p.data"></div>
            <!-- <Image :value="p.src"></Image> -->
            <!-- <CarouselComponent></CarouselComponent> -->

            
        </el-card>
    </el-scrollbar>

    </div>
</template>

<script lang="ts" setup>
    import { defineProps } from 'vue'
    import Image from '@/components/Image.vue'
    import { useRoute, useRouter } from 'vue-router';

    const router = useRouter();
    const redirect = (path: string) => {
        router.push(path);
    }

    const paragraph = defineProps(['value', 'click']);

</script>
  
<style scoped>
    p {
        margin: 0;
        line-height: 1.6;
        font-size: 1rem;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }

    .box-card {
        width: 100%;
    }
    .line-with-text {
        display: flex;
        align-items: center;
        color: #8A6B08;
        /* color: #eebbc3; */
    }

    .divider {
        flex: 1;
        margin: 0 20px;
    }
</style>