<template>
  <div class="education">
      <h1>EDUCATION</h1>
      <CardImg :value="eduTitle"></CardImg>
  </div>
</template>
<script lang="ts" setup>
  import { reactive } from 'vue';
  import CardImg from '@/components/CardImg.vue';

  const eduTitle = reactive([
      {
        id:0, 
        title:'Student Benefit', 
        img:'edu1.jpg', 
        route:'/student', 
        desc:'Maximize your learning experience with exclusive student benefits'
      },
      {
        id:1, 
        title:'RTO Partners', 
        img:'edu2.jpg', 
        route:'/rto', 
        desc:'Join a network of leading Registered Training Organizations'
      },
      {
        id:3, 
        title:'Upcoming Events - free seminars', 
        img:'edu3.jpg', 
        route:'/event', 
        desc:'Join free seminars to dicuss with us about the latest information'
      },
      // {
      //   id:2, 
      //   title:'Current Courses', 
      //   img:'edu3.jpg', 
      //   route:'/course', 
      //   desc:'Stay ahead with our dynamic selection of up-to-date courses'
      // }
  ]);

</script>

<style scoped>
  h1 {
    font-size: 2.5rem;
    font-weight: 400;
  }
</style>