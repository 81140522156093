<template>
  <div class="staff">
      <h1>STAFF PORTAL</h1>
      <!-- <div class="flex">
        <div style="flex: 2;" />
        <div style="flex: 8;">
          <el-page-header @back="onBack"></el-page-header>
        </div>
        <div style="flex: 11;"></div>
      </div> -->
      
      <div class="flex">

        <div style="flex: 2;" />

        <div style="flex: 17;">
          <!-- <el-tabs v-model="activeTab" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="Document Archive" name="document">
              <Document v-if="isAdminValid"></Document>
              <LoginForm style="padding: 0 10rem;" v-else></LoginForm>
          </el-tab-pane>
            <el-tab-pane label="OBA Review Team" name="oba">
              <StudentForm v-if="isOBAValid"></StudentForm>
              <LoginForm style="padding: 0 10rem;" v-else></LoginForm>
          </el-tab-pane>
          </el-tabs> -->
          <Document v-if="isAdminValid"></Document>
          <LoginForm style="padding: 0 10rem;" v-else></LoginForm>
          <!-- <StudentForm></StudentForm> -->
        </div>

        <div style="flex: 2;" />
      </div>
      
      <br>
  </div>
</template>
<script lang="ts" setup>
    import { inject, onBeforeMount, ref, reactive } from 'vue'
    import type { TabsPaneContext } from 'element-plus'
    import { useRoute, useRouter } from 'vue-router'
  
    import CardText from '@/components/CardText.vue'
    import StudentForm from '@/components/StudentForm.vue'
    import LoginForm from '@/components/LoginForm.vue'
    import Document from '@/components/Document.vue'

    const $axios : any = inject('axios');
  
    const route = useRoute();
    const router = useRouter();
  
    const activeTab = typeof route.params.tab === 'string' ? route.params.tab : 'document';

    const isAdminValid = ref(false);
    const isOBAValid = ref(false);

    const handleClick = (tab: TabsPaneContext, event: Event) => {
        // console.log(isAdminValid.value, isOBAValid.value);  
    }

    // onBeforeMount(() => {
    //     $axios.get('/user/admin/validate').then((res: any) => {
    //         if (res.data.code === 200) {
    //             isAdminValid.value = true;
    //         }
    //         else {
    //             isAdminValid.value = false;
    //         }
    //     }).catch((err: any) => {
    //         isAdminValid.value = false;
    //     });

    //     $axios.get('/user/oba/validate').then((res: any) => {
    //         if (res.data.code === 200) {
    //             isOBAValid.value = true;
    //         }
    //         else {
    //             isOBAValid.value = false;
    //         }
    //     }).catch((err: any) => {
    //         isOBAValid.value = false;
    //     });
    // });
  
    const onBack = () => {
      router.back();
      // notify('Back')
    }
    
  
</script>
  <!-- <style>
    .el-tabs__item {
      font-family: Lato, sans-serif;
      color: #232946;
    }
    .el-tabs__item:hover {
      color: #d4939d !important;
    }
    .el-tabs__item.is-active {
      color: #d4939d !important;
    }
    .el-tabs__active-bar {
      background-color: #d4939d !important;
    }
  </style> -->
<style scoped>
  /* .line-with-text {
        display: flex;
        align-items: center;
    }
  
    .divider {
        flex: 1;
        margin: 0 20px;
    } */
    h1 {
      font-size: 2.5rem;
      font-weight: 400;
    }
</style>