<template>
    <div class="rto">
        <h1>RTO PARTNERS</h1>
        <PageHeader :value="data"></PageHeader>
        <br>
    </div>
</template>

<script lang="ts" setup>
    import { ref, reactive } from 'vue'
    import PageHeader from '@/components/wap/PageHeader-wap.vue';

    const paragraph = [
      {
        title:'On Delivery Partnerships',
        data:'&nbsp&nbsp&nbsp1.&nbsp&nbsp&nbspCreate a new income stream for the RTO<br>&nbsp&nbsp&nbsp2.&nbsp&nbsp&nbspGEP will be able to match the Australian RTO with a reputable College partner overseas.<br>&nbsp&nbsp&nbsp3.&nbsp&nbsp&nbspGEP will manage the delivery partnership to produce expected outcomes.<br>&nbsp&nbsp&nbsp4.&nbsp&nbsp&nbspGEP will ensure that delivery is within ASQA Compliance if required<br>&nbsp&nbsp&nbsp5.&nbsp&nbsp&nbspIt reduces the cost of travels for the Australian RTO looking for delivery partners overseas.'
      },
      {
        title:'On Student Recruitment',
        data:'&nbsp&nbsp&nbsp1.&nbsp&nbsp&nbspIncrease number of International Students for RTO.<br>&nbsp&nbsp&nbsp2.&nbsp&nbsp&nbspChoose particular Qualifications to promote to targeted audience<br>&nbsp&nbsp&nbsp3.&nbsp&nbsp&nbspGEP recruits genuine students and prepares them based on eligibility required by RTO including English requirements.<br>&nbsp&nbsp&nbsp4.&nbsp&nbsp&nbspGEP assists students for accommodation, meet and greet and orientation on arrival in Australia.</p>'
      }
    ];

    const data = reactive(
      {
        paragraph:paragraph,
        rootPath:'/education-wap',
        root:'Education',
        current:'rto partners',
        currentPath:'/rto-wap',
        inquiryPath:'/contact-wap',
        isPara:true,
        isTable:false
      }
    );

</script>

<style scoped>
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
</style>