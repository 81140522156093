<template>
  <el-menu
    router
    :default-active="route.path"
    class="el-menu-demo menu-with-shadow"
    mode="horizontal"
    background-color="#232946"
    text-color="#fffffe"
    active-text-color="#eebbc3"
    :ellipsis="false"
    @select="handleSelect"
    style="height: 6rem;"
    menu-trigger="click"
    :close-on-click-outside="true"
  >
    <div class="flex-grow" />
    <el-menu-item @click="backToHome">
      <img
        style="width: 6rem; height: 8rem;"
        src="@/assets/wap/logo.png"
        alt="GEP Group"
      />
      <!-- <h3 style="font-weight: 500; font-size: 1.5rem;">GEP GROUP</h3> -->
    </el-menu-item>
    <div style="flex-grow: 10;" />
    <!-- <el-menu-item index="/home">Home</el-menu-item> 
    <el-menu-item index="/about">About</el-menu-item>
    <el-menu-item index="/education">Education</el-menu-item>
    <el-menu-item index="/training">Training</el-menu-item>
    <el-menu-item index="/employment">Employment</el-menu-item>
    <el-menu-item index="/contact">Contact</el-menu-item>
    <el-menu-item index="/staff">Staff Portal</el-menu-item> -->
    
    <el-sub-menu index="3" popper-class="selectStyle" id="one">
      <template #title>Services</template>
      <el-menu-item index="/about-wap">About</el-menu-item>
      <el-menu-item index="/education-wap">Education</el-menu-item>
      <el-menu-item index="/training-wap">Training</el-menu-item>
      <el-menu-item index="/employment-wap">Employment</el-menu-item>
      <el-menu-item index="/contact-wap">Contact</el-menu-item>
      <el-menu-item index="/staff-wap">Staff Portal</el-menu-item>
    </el-sub-menu>
    <div class="flex-grow" />
  </el-menu>
</template>
  
<script lang="ts" setup>
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router';
  
  const route = useRoute();
  const router = useRouter();
  
  const backToHome = () => {
    router.push('/home');
  }
  
  const handleSelect = (key: string, keyPath: string[]) => {
    
  }
</script>

<style>
  .el-sub-menu__title {
    font-size: 1rem !important;
    font-weight: 500;
  }

  /* .el-sub-menu .el-sub-menu__icon-arrow {
    display: none !important;
  } */
  .el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title {
    border-bottom: none !important;
  }
  /* .el-menu:not(.el-menu--collapse) .el-sub-menu__title {
    padding: 0 20px !important;
  } */
  
</style>

 
<style scoped>
  .menu-with-shadow {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
    z-index: 999;
  }
  h3 {
    color:#fffffe;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .el-menu-item {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent !important;
    font-family: Lato, sans-serif;
    font-weight: 300;
  }
  .el-sub-menu {
    -webkit-tap-highlight-color: transparent;
  }
  .el-menu-item.is-active {
    background-color: transparent !important;
    font-weight: 500;
  }
  .el-menu-item:hover{
    background-color: transparent !important;
    color: #eebbc3 !important;
    /* text-decoration: underline; */
  }
</style>