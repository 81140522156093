<template>
    <div class="event">
        <h1>UPCOMING EVENTS</h1>
        <PageHeader :value="data"></PageHeader>
        <br>
    </div>
</template>
<script lang="ts" setup>
    import { ref, reactive } from 'vue'
    import PageHeader from '@/components/wap/PageHeader-wap.vue';
    import CarouselComponent from '@/components/CarouselComponent.vue'

    const img = reactive([
        {
        title:'Upcoming FREE Information Seminars in the Philippines',
        data:[
            {
            desc:'<b>Baguio City&nbsp&nbsp&nbsp16 January 2024</b>',
            src:'All - FLYER AGRI. BAGUIO.png'
            },
            {
            desc:'<b>Zamboangs City&nbsp&nbsp&nbsp20 January 2024</b>',
            src:'All - FLYER AGRI. ZAMB.png'
            }
        ]
        }
    ]);


    const data = reactive(
      {
        img:img,
        rootPath:'/education',
        root:'Education',
        current:'event',
        currentPath:'/event-wap',
        inquiryPath:'/contact-wap',
        isPara:false,
        isTable:false,
        isCarousel:true
      }
    );

</script>

<style scoped>
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
</style>