<template>
    <div aria-label="A complete example of page header" style="padding: 0 2vh;">
      <el-page-header @back="onBack">
        <template #breadcrumb>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: data.rootPath }">
              {{ data.root }}
            </el-breadcrumb-item>
            <el-breadcrumb-item><a :href=data.currentPath>{{ data.current }}</a></el-breadcrumb-item>
            <el-breadcrumb-item v-if="data.isDetail">{{ data.detail }}</el-breadcrumb-item>
          </el-breadcrumb>
        </template>
        <template #extra>
          <div class="flex items-center">
            <button class="button-23" @click="redirect()">Inquiry</button>
          </div>
        </template>
  
        <CardText v-if="data.isPara" :value="data.paragraph"></CardText>
        <Table v-if="data.isTable" :value="data.table"></Table>
        <CarouselComponent v-if="data.isCarousel" :value="data.img"></CarouselComponent>
        
  
        <!-- <p class="mt-4 text-sm">
          Element Plus team uses <b>weekly</b> release strategy under normal
          circumstance, but critical bug fixes would require hotfix so the actual
          release number <b>could be</b> more than 1 per week.
        </p> -->
      </el-page-header>
    </div>
</template>
  
<script setup lang="ts">
    import { ElNotification as notify } from 'element-plus'
    import { ref, reactive, defineProps } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import CardText from '@/components/CardText.vue'
    import Table from '@/components/Table.vue'
    import CarouselComponent from '@/components/wap/Carousel-wap.vue'
  
    const props = defineProps(['value']);
    const data = props.value;
  
  
    const router = useRouter();
    const onBack = () => {
      router.back();
      // notify('Back')
    }
    const redirect = () => {
      router.push(data.inquiryPath);
    }
</script>
    