<template>
    <el-card v-if="typeof img.value === 'string'"
        class="custom-card" 
        :body-style="{ padding: '0px' }" 
        :style="{ backgroundImage: `url('${require(`@/assets/${img.value}`)}')` }" 
    />
</template>

<script lang="ts" setup>
    import { defineProps } from 'vue'
    const img = defineProps(['value']);
</script>

<style scoped>
  .custom-card {
    /* background-image: url('@/assets/edu1.jpg'); */
    background-size: 100% 100% !important;
    background-position: center;
    height: 50rem;
  }
</style>