<template>
  <el-row>
      <div style="flex-grow: 1;" />
      <el-col
          v-for="title in titles.value"
          :key="title.id"
          :span="6"
          :offset="title.id > 0 ? 1 : 0"
      >
          <el-card :body-style="{ padding: '0px' }">
          <el-card 
              class="custom-card" 
              :body-style="{ padding: '0px' }" 
              :style="{ backgroundImage: `url('${require(`@/assets/${title.img}`)}')` }" 
          />

          <div style="padding: 14px">
              <span>{{ title.title }}</span>
              <div class="bottom">
              <time class="time">{{ title.desc }}</time>
              <el-button text class="button" @click="redirect(title.route)">Check It</el-button>
              </div>
          </div>
          </el-card>
      </el-col>
      <div style="flex-grow: 1;" />
  </el-row>
  <br>
</template>
  
<script lang="ts" setup>
  import { ref } from 'vue'
  import { defineProps } from 'vue'
  import { useRoute, useRouter } from 'vue-router';

  const router = useRouter();
  const redirect = (path: string) => {
    router.push(path);
  }

  const titles = defineProps(['value']);
  
  </script>
  
  <style scoped>
  .custom-card {
    /* background-image: url('@/assets/edu1.jpg'); */
    background-size: cover !important;
    background-position: center;
    height: 23rem;
  }
  .time {
    font-size: 12px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button {
    padding: 0;
    min-height: auto;
  }
  
  .image {
    width: 100%;
    display: block;
  }
  </style>