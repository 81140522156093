<template>
    <div class="trainingBrief">
        <h1>OCCUPATIONAL TRAINEESHIP</h1>
        <PageHeader :value="data"></PageHeader>
        <br>
    </div>
</template>

<script lang="ts" setup>
    import { inject, onBeforeMount, ref, reactive } from 'vue'
    import PageHeader from '@/components/wap/PageHeader-wap.vue';

    const $axios : any = inject('axios');

    const table = ref([]);

    // onBeforeMount(() => {
    //   $axios.get('/courses/all').then((res: any) => {
    //     table.value = res.data;
    //   })
    // });

    const paragraph = [
      {
        title:'Introduction',
        data:'GEP assists RTO’s and Employers with approved 407 Sponsorship.<br><br>For detail of Australia’s Sub class 407 Sponsorship,  please click the following link<br><a href="https://www.border.gov.au/Trav/Visa-1/407">https://www.border.gov.au/Trav/Visa-1/407</a><br><br>GEP is  currently involved for the Recruitment of the following professions for Visa 407 on behalf of approved Sponsors<br>&nbsp&nbsp&nbsp1.&nbsp&nbsp&nbspRegistered Nurses<br>&nbsp&nbsp&nbsp2.&nbsp&nbsp&nbspCooks<br>&nbsp&nbsp&nbsp3.&nbsp&nbsp&nbspAgriculture Technicians'
      },
      {
        title:'What is Occupational Traineeship?',
        data:'Subclass 407 Training visa is a temporary visa which allows sponsored individual to 	participate in workplace-based occupational training activities to improve skills 		related to occupation, area of tertiary study, field of expertise, or to participate in a 	professional development training program in Australia.<br><br>There are 3 types of occupational training covered by this visa:<br>&nbsp&nbsp&nbsp1.&nbsp&nbsp&nbspOccupational training required for registration<br>&nbsp&nbsp&nbsp2.&nbsp&nbsp&nbspOccupational training to improve skills in an eligible occupation<br>&nbsp&nbsp&nbsp3.&nbsp&nbsp&nbspOccupational training for capacity building overseas'
      },
      {
        title:'Occupational training to improve skills in an eligible occupation',
        data:'Workplace-based structured training programs that:<br><br>•	is specifically tailored and timed to the training needs of the nominated person<br>•	is in relation to an occupation specified on the list of eligible skilled occupations<br>•	involves a nominee who has recent and suitable prior experience. This can include employment experience or study experience that:<br><br>&nbsp&nbsp&nbsp1.&nbsp&nbsp&nbspis the equivalent of at least 12 months full-time experience in the occupation<br>&nbsp&nbsp&nbsp2.&nbsp&nbsp&nbsphas occurred in the 24 months immediately before the nomination is lodged'
      },
      {
        title:'Occupational training for capacity building overseas',
        data:'Occupational training for capacity building overseas includes:<br><br>Overseas qualification<br>Structured workplace-based training in Australia for up to 6 months for students 	who:<br>•	are currently enrolled in a foreign educational institution and<br>•	must complete a period of practical training, research or observation to get their qualification<br>•	The training must be specifically tailored to the training needs of the nominated person.<br><br>The training must be specifically tailored and timed to the training needs of the nominated person<br><br>Professional development<br>Professional development programs of face-to-face teaching in a classroom or 		similar environment in Australia. It is for overseas employers to send their 	managerial or professional employees.'
      },
      {
        title:'How long is the Occupational Traineeship Program?',
        data:'The programs we are currently offering is only for one (1) year.  However, Visa Sub-class 407allows the trainee to stay for maximum of two (2) years.'
      },
      {
        title:'Who will process the Visa?',
        data:'GEP has in-house MARA (Migration Agents Registration Authority) accredited agents who are experts in various visa applications including Sub-class 407.'
      },
      {
        title:'How much will I pay if I participate?',
        data:'The fee is only AUD10,000. However, the applicant only initially pays AUD300. The rest of the amount is only payable when the applicant successfully granted Visa.  The fee will cover the following:<br><br>•	Job Ready Program<br>•	Visa Lodgement Fees<br>•	Health Insurance<br>•	Airfare<br>•	Airport Transfers<br>•	Accommodation and Cost of Living Allowance for the first month'
      },
      {
        title:'Do you have in-house Loan available?',
        data:'GEP is in partnership with Peppermint Innovations, an Australian Company with a subsidiary in the Philippines PFI Finance.  If you eligible but cannot afford the fees, you may apply to PFI Finance for loan.'
      },
      {
        title:'What do I do if I am eligible and interested to apply?',
        data:'If you meet the eligibility criteria and is interested to apply, please send your resume and your College Certificate to info@gepgrp.com<br><br>If you are chosen, you be invited to attend a free webinar.  The webinar serves as information session about the Occupational Traineeship Program.  Attending the Webinar is part of the Application Process.  You will not be invited to proceed your application if you fail to attend the Information Session.'
      }
    ];

    const data = reactive(
      {
        paragraph:paragraph,
        table:table,
        rootPath:'/training',
        root:'Training',
        current:'occupational traineeship',
        currentPath:'/training-brief',
        inquiryPath:'/contact-wap',
        isPara:true,
        isTable:true
      }
    );

</script>

<style scoped>
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
</style>