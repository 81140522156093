<template>
    <div class="course">
        <h1>CURRENT COURSES</h1>
        <PageHeader :value="data"></PageHeader>
        <br>
    </div>
</template>
<script lang="ts" setup>
    import { inject, onBeforeMount, ref, reactive } from 'vue'
    import PageHeader from '@/components/PageHeader.vue';

    const $axios : any = inject('axios');

    let table = ref([]);

    onBeforeMount(() => {
      $axios.get('/courses/all').then((res: any) => {
        table.value = res.data;
      });
    });

    const data = reactive(
      {
        table:table,
        rootPath:'/education',
        root:'Education',
        current:'current courses',
        currentPath:'/course',
        inquiryPath:'/contact/student',
        isPara:false,
        isTable:true
      }
    );

</script>

<style scoped>
  h1 {
    font-size: 2.5rem;
    font-weight: 400;
  }
</style>