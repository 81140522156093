<template>
    <div class="cook">
        <h1>OCCUPATIONAL TRAINEESHIP FOR COOKS</h1>
        <PageHeader :value="data"></PageHeader>
        <br>
    </div>
</template>

<script lang="ts" setup>
    import { inject, onBeforeMount, ref, reactive } from 'vue'
    import PageHeader from '@/components/wap/PageHeader-wap.vue';

    const $axios : any = inject('axios');

    const table = ref([]);

    // onBeforeMount(() => {
    //   $axios.get('/courses/all').then((res: any) => {
    //     table.value = res.data;
    //   })
    // });

    const img = [
      {
        title:'Upcoming FREE Information Seminars in the Philippines',
        data:[
          {
            desc:'Baguio City&nbsp&nbsp&nbsp16 January 2024',
            src:'All - FLYER HOSPITALITY. BAGUIO.png'
          },
          {
            desc:'Zamboangs City&nbsp&nbsp&nbsp20 January 2024',
            src:'All - FLYER HOSPITALITY. ZAMB.png'
          }
        ]
      }
    ];

    const paragraph = [
      {
        title:'Introduction',
        data:'This Traineeship Program is sponsored by Complete Hospitality Training CHT).  This traineeship will provide participants the experience to work within the Australian hospitality industry, mainly as Cooks.<br><br>Complete Hospitality Training has designed a Job Ready Program for participants before embarking into a hands-on traineeship with various employers.<br><br>GEP GROUP partners with CHT in bringing this program to Bachelor Graduates in Hospitality or Culinary in their respective countries.'
      }
    ];

    const data = reactive(
      {
        paragraph:paragraph,
        table:table,
        img:img,
        rootPath:'/training',
        root:'Training',
        current:'cook training',
        currentPath:'/cook',
        inquiryPath:'/contact-wap',
        isPara:true,
        isTable:true,
        isCarousel:true
      }
    );

</script>

<style scoped>
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
</style>