<template>
    <div class="employment">
        <h1>EMPLOYMENT</h1>
        <div style="padding: 0 2vh;">
            <el-page-header @back="onBack"></el-page-header>
        </div>
        <div style="padding: 0 2vh;">
            <CardText :value="data.paragraph"></CardText>
        </div>
        
        <br>
    </div>
  </template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CardText from '@/components/CardText.vue'

const router = useRouter();
const onBack = () => {
  router.back();
  // notify('Back')
}

const paragraph = [
  {
    title:'Skill Shortage Assistance',
    data:'1. Assist Employers experiencing Skills Shortage in Australia to recruited Skill labour overseas under Labour Hire Agreement and Visa 482 or Visa 494 together with our Migration Partners.<br><br>2.	Assist Australian Employers who would like to recruit in the Philippines through POEA Accredited Agents.'
  }
];

const data = reactive(
  {
    paragraph:paragraph
  }
);
</script>

<style scoped>
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
</style>