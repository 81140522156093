<template>
    <div class="courseDetail">
        <h1>COURSE DETAILS</h1>
        <PageHeader :value="data"></PageHeader>
        <br>
    </div>
</template>
<script lang="ts" setup>
    import { inject, onBeforeMount, onMounted, ref, reactive } from 'vue'
    import { useRoute, useRouter } from 'vue-router';
    import PageHeader from '@/components/PageHeader.vue';

    const $axios : any = inject('axios');
    const route = useRoute();

    const paragraph = ref([]);

    onBeforeMount(() => {
      $axios.get('/courses/desc', {params:{
        id:route.params.id
      }
      }).then((res: any) => {
        paragraph.value = res.data;
      });
    });

    
    const table = [
      {
        title:'Units of competency',
        isUnit:true,
        data:[
          {
            code: 'TAEXDB511',
            name: 'Develop and implement training and assessment strategies for learners with disability',
            usage: 'current',
            essential: 'elective'
          },
          {
            code: 'TAEXDB411',
            name: 'Plan and implement individual support plans for learners with disability',
            usage: 'current',
            essential: 'elective'
          },
          {
            code: 'TAETAS411',
            name: 'Maintain training and assessment information',
            usage: 'current',
            essential: 'elective'
          },
          {
            code: 'TAEPDD401',
            name: 'Work effectively in the VET sector',
            usage: 'current',
            essential: 'core'
          },
          {
            code: 'TAELLN423',
            name: 'Integrate employability skills support into training and assessment',
            usage: 'current',
            essential: 'elective'
          },
          {
            code: 'TAELLN422',
            name: 'Use foundation skills resources, strategies and advice',
            usage: 'current',
            essential: 'elective'
          },
          {
            code: 'TAELLN421',
            name: 'Integrate core skills support into training and assessment',
            usage: 'current',
            essential: 'elective'
          },
          {
            code: 'TAEDES412',
            name: 'Design and develop plans for vocational training',
            usage: 'current',
            essential: 'core'
          },
          {
            code: 'TAEDES411',
            name: 'Use nationally recognised training products to meet vocational training needs',
            usage: 'current',
            essential: 'core'
          },
          {
            code: 'TAEDEL416',
            name: 'Facilitate learning for young vocational learners',
            usage: 'current',
            essential: 'elective'
          }
        ]
      }
    ];

    const data = reactive(
      {
        table:table,
        paragraph:paragraph,
        rootPath:'/education',
        root:'Education',
        current:'current courses',
        currentPath:'/course',
        detail:'course detail',
        isPara:true,
        isTable:true,
        isDetail:true
      }
    );
    

</script>

<style scoped>
  h1 {
    font-size: 2.5rem;
    font-weight: 400;
  }
</style>