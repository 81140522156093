<template>
  <el-menu
    router
    :default-active="route.path"
    class="el-menu-demo"
    mode="horizontal"
    background-color="#232946"
    text-color="#fffffe"
    active-text-color="#eebbc3"
    :ellipsis="false"
    @select="handleSelect"
    style="height: 6rem"
  >
    <div class="flex-grow" />
    <el-menu-item @click="backToHome">
      
      <img
        style="width: 6rem; height: 8rem;"
        src="@/assets/logo.png"
        alt="GEP Group"
      />
      <h1 style="font-weight: 500; font-size: 1.5rem;">GEP GROUP</h1>
    </el-menu-item>
    <div style="flex-grow: 10;" />
    <el-menu-item index="/home">Home</el-menu-item> 
    <el-menu-item index="/about">About</el-menu-item>
    <el-menu-item index="/education">Education</el-menu-item>
    <el-menu-item index="/training">Training</el-menu-item>
    <el-menu-item index="/employment">Employment</el-menu-item>
    <el-menu-item index="/contact">Contact</el-menu-item>
    <el-menu-item index="/staff">Staff Portal</el-menu-item>
    <div class="flex-grow" />
    <!-- <el-sub-menu index="3">
      <template #title>Workspace</template>
      <el-menu-item index="3-1">item one</el-menu-item>
      <el-menu-item index="3-2">item two</el-menu-item>
      <el-menu-item index="3-3">item three</el-menu-item>
      <el-sub-menu index="3-4">
        <template #title>item four</template>
        <el-menu-item index="3-4-1">item one</el-menu-item>
        <el-menu-item index="3-4-2">item two</el-menu-item>
        <el-menu-item index="3-4-3">item three</el-menu-item>
      </el-sub-menu>
    </el-sub-menu> -->
  </el-menu>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const backToHome = () => {
  router.push('/home');
}

const handleSelect = (key: string, keyPath: string[]) => {
  
}
</script>

<style scoped>
  h1 {
    color:#fffffe;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .el-menu-item {
    background-color: transparent !important;
    font-family: Lato, sans-serif;
    font-weight: 300;
  }
  .el-menu-item.is-active {
    background-color: transparent !important;
    font-weight: 500;
  }
  .el-menu-item:hover{
      background-color: transparent !important;
      color: #eebbc3 !important;
      /* text-decoration: underline; */
  }
</style>