<template>
    <div v-for="t in table.value">
    <h5 class="line-with-text">
        <el-divider class="divider" border-style="double" />
        {{ t.title }}
        <el-divider class="divider" border-style="double" />
    </h5>
    <el-table 
    :data="t.data" 
    stripe border 
    :style="{ boxShadow: `var(--el-box-shadow-lighter)`}" 
    max-height="250"
    style="width: 100%; font-family: Lato, sans-serif; font-weight:400; color: #232946;"
    >
        <el-table-column prop="code" label="Code" width="120" />
        <el-table-column prop="name" label="Name" width="840" />
        <el-table-column prop="usage" label="Usage"/>
        <el-table-column v-if="t.isUnit" fixed="right" prop="essential" label="Essential"/>
        <el-table-column v-else fixed="right" label="Operations">
        <template #default="scope">
            <el-button 
            style="text-decoration: underline; color: #232946;" 
            link type="primary" 
            size="small" 
            @click="redirect(`/course-detail/${scope.row.id}`)"
            >Detail</el-button>
            <!-- <el-button style="text-decoration: underline; color: #232946;" link type="primary" size="small">RTO</el-button> -->
        </template>
        </el-table-column>
    </el-table>
    </div>
</template>
  
<script lang="ts" setup>
  import { defineProps } from 'vue'
  import { useRoute, useRouter } from 'vue-router';

  const router = useRouter();
  const redirect = (path: string) => {
    router.push(path);
  }
  const table = defineProps(['value']);
</script>

<style scoped>
.line-with-text {
  display: flex;
  align-items: center;
}

.divider {
  flex: 1;
  margin: 0 20px;
}
</style>