<template>
    <div v-for="image in img.value">
      <h3 class="line-with-text">
          <el-divider class="divider" border-style="double" />
          {{ image.title }}
          <el-divider class="divider" border-style="double" />
      </h3>
      <el-carousel :interval="4000" arrow="always" style="height: 54vh;" >
        <el-carousel-item v-for="(item, index) in image.data" :key="index" style="height: 54vh;">
          <el-card
            body-style="padding: 0;"
          >
            <div v-html="item.desc"></div>
            <Image :value="item.src"></Image>
          </el-card>
        </el-carousel-item>
      </el-carousel>
    </div>
  
</template>

<script lang="ts" setup>
  import {defineProps, ref, reactive} from 'vue'
  import Image from '@/components/wap/Image-wap.vue'

  const img = defineProps(['value']);
  
</script>

<style scoped>
  .custom-card img {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
  }
  p {
    margin: 0;
    line-height: 1.6;
    font-size: 1rem;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .box-card {
    width: 100%;
  }
  .line-with-text {
    display: flex;
    align-items: center;
    color: #8A6B08;
  }

  .divider {
    flex: 1;
    margin: 0 20px;
  }
</style>