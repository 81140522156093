<template>
    <div class="about">
        <h1>ABOUT US</h1>
        <div style="padding: 0 2vh;">
            <el-page-header @back="onBack"></el-page-header>
        </div>

        <div style="padding: 0 2vh;">
            <CardText :value="data.paragraph"></CardText>
            <h3 class="line-with-text">
                <el-divider class="divider" border-style="double" />
                <div style="color: #8A6B08;">Work Model</div>
                <el-divider class="divider" border-style="double" />
            </h3>
            <Image :value="img"></Image>
            <CardText :value="joinUs" :click="redirect"></CardText>
        </div>
        
        <br>
    </div>
</template>

<script lang="ts" setup>
    import { ref, reactive } from 'vue'
    import type { TabsPaneContext } from 'element-plus'
    import { useRoute, useRouter } from 'vue-router'

    import CardText from '@/components/CardText.vue'
    import Image from '@/components/wap/Image-wap.vue'

    const route = useRoute();
    const router = useRouter();

    const onBack = () => {
    router.back();
    // notify('Back')
    }

    const img = ref('work-model.png');

    const redirect = ref('/contact-wap');

    const joinUs = reactive([
    {
        title:'Join Us',
        data:'<b style="text-decoration: underline; cursor: pointer;">APPLY NOW and BE OUR GEP Overseas Business PARTNER</b>'
    }
    ]);

    const paragraph = [
    {
        title:'Introduction',
        data:'The GEP Group is an Australian based company whose main objective is to create educational alliances and partnerships with various Colleges outside Australia.<br><br>GEP GROUP has three main core businesses, Education-Training-Employment.'
    },
    {
        title:'Education',
        data:'1. To introduce Australian Vocational Education and Training (VET) curriculum to another college overseas through agreed partnership terms.  The issuance of qualification is based on ASQA (Australian Skills and Quality Authority) standards.<br><br>2. Recruitment of genuine students for Australian RTOs and Universities with completed eligibility and qualifications as required.'
    },
    {
        title:'Traineeship',
        data:'1. GEP assists Sub Class Visa 407 holders in recruiting appropriate candidates ready for Occupational Traineeships.<br><br>2. Can create Training Plans for various individuals or industry.<br><br>3. Recruit 407 Candidates for Approved Sponsors.<br><br>4. Deliver the Outcome Based Assessment (OBA) Review for Internationally Qualified Nurses (IQN).'
    },
    {
        title:'Employment',
        data:'1. Assist Employers experiencing Skills Shortage in Australia to recruited Skill labour overseas under Labour Hire Agreement and Visa 482 or Visa 494 together with our Migration Partners.<br><br>2.	Assist Australian Employers who would like to recruit in the Philippines through POEA Accredited Agents.'
    },
    {
        title:'Gep Group Overseas Business Partners',
        data:'GEP is looking for Business Partners in other countries.<br><br><b>ELIGIBILITY REQUIREMENT FOR GEP OVERSEAS PARTNER</b><br>1.	If with existing business, it must be profitable in the last three years<br>2. If it is a new set-up business, owners must be financially capable.<br>3. With good and ethical standing as a business.<br>4. Must be able to afford an Office with at least one training room (or boardroom).<br>5.	Three Business References Required (partners and supplier).<br><br><b>HOW DOES THE PARTNERSHIP WORK?</b><br>1. Australian Colleges look for Overseas delivery partners and International Students.<br>2. GEP Overseas Partner will find an appropriate College to partner with the Australian RTO and will also recruit for Students who would like to study in Australia.<br><br><b>INCOME STREAMS FOR GEP OVERSEAS PARTNERS</b><br>1.	On Delivery Partnerships<br>2. On Student Recruitment<br>3. Recruitment for Occupational Traineeship (Visa 407)'
    }
    ];

    const data = reactive(
    {
        paragraph:paragraph
    }
);
  

</script>

<style scoped>
    .line-with-text {
        display: flex;
        align-items: center;
    }

    .divider {
        flex: 1;
        margin: 0 20px;
    }
    h1 {
        font-size: 2.5rem;
        font-weight: 400;
    }
</style>