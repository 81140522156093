<template>
    <div>
        <h5 class="line-with-text">
            <el-divider class="divider" border-style="double" />
            Document Archive
            <el-divider class="divider" border-style="double" />
        </h5>
        <div v-for="r in resultArray">
            <h5 class="line-with-text">
                <el-divider class="divider" border-style="double"/>
                {{r.title}}
                <el-divider class="divider" border-style="double"/>
            </h5>
            <el-table 
            :data="r.data" 
            stripe border 
            :style="{ boxShadow: `var(--el-box-shadow-lighter)`}" 
            max-height="250"
            style="width: 100%; font-family: Lato, sans-serif; font-weight:400; color: #232946;"
            >
                <el-table-column prop="extension" label="Type" width="120"/>
                <el-table-column prop="name" label="Name" />
                <el-table-column label="Operations" width="120" >
                <template #default="scope">
                    <el-button 
                    link type="primary" 
                    size="small"
                    ><a 
                    :href="scope.row.content" 
                    :download="scope.row.name" 
                    style="text-decoration: underline; color: #232946;" 
                    >Download</a></el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- <ul>
            <li v-for="(asset, index) in assets" :key="index">
            <a :href="asset.content" :download="asset.name">{{asset.name}}</a>
            </li>
        </ul> -->
    </div>
  </template>

<script lang="ts" setup>
    const scan = (context:any, supportedExtensions:any) => {
        const keys = context.keys();

        return keys.reduce((result:any, key:any) => {
            const relativePath = key.substring(2); // 去掉 "./"
            const [title, nameWithExtension] = relativePath.split('/'); // 假设子目录名即为 title

            const [name, extension] = nameWithExtension.split('.');

            if (supportedExtensions.includes(extension.toLowerCase())) {
                // 初始化 title 对应的数据结构
                if (!result[title]) {
                    result[title] = {
                    title: title,
                    data: [],
                    };
                }

                // 读取模块内容
                const content = context(key).default;

                // 将数据添加到对应的 title 下
                result[title].data.push({
                    name: name,
                    extension: extension,
                    content: content,
                });
            }

            return result;
        }, {});
    }
    const supportedExtensions = ['pdf', 'docx', 'xlsx'];

    const assetsContext = require.context('@/assets/doc', true, /\.(pdf|docx|xlsx)$/);
    const scannedData = scan(assetsContext,supportedExtensions);
    const resultArray = Object.values(scannedData);

    // const assetsContext = require.context('@/assets/doc/', false, /\.(pdf)$/);

    // const assets = assetsContext.keys().map(key => {
    //     const fileName = key.substring(2);
    //     return {
    //         name: fileName,
    //         content: assetsContext(key).default,
    //     };
    // });

    // const assets = require.context('@/assets/doc/', false, /\.(pdf)$/).keys().map(key => {
    //     return {
    //         name: key.substring(2),
    //         content: require('@/assets/doc/' + key.substring(2)).default,
    //     };
    // });

</script>

<style scoped>
  .line-with-text {
      display: flex;
      align-items: center;
  }

  .divider {
      flex: 1;
      margin: 0 20px;
  }
</style>