<!-- BackgroundComponent.vue -->
<template>
    <div style="width: 100%;">
      <el-card class="custom-card" body-style="padding:0;">
        <el-row style="height: 100vh;">
          <el-col :span="12" style="background-color: rgba(27, 43, 64, 0.2);">
            <div class="left-align" style="padding-left: 3vh; padding-top: 8vh;">
              <h3 class="selectable" @click="redirect('/education-wap')">Education</h3>
              <h3 class="selectable" @click="redirect('/training-wap')">Training</h3>
              <h3 class="selectable" @click="redirect('/employment-wap')">Employment</h3>
              <h5 style="margin: 4rem 0;">Start Your Dream In Australia</h5>
              <button class="btn button-23" @click="redirect('/contact-wap')">CONTACT US</button>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
</template>
  
<script lang="ts" setup>
    import { useRoute, useRouter } from 'vue-router';
    const router = useRouter();
    const redirect = (path: string) => {
      router.push(path);
    }
</script>
  
<style scoped>
  .selectable {
    width: 15rem;
  }
  .selectable:hover {
    color: #eebbc3;
    text-decoration: underline;
    cursor: pointer;
  }
  .btn {
    font-weight: 800;
    padding: 10px 16px;
    font-size: 13px;
    letter-spacing: 2px;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .left-align {
    text-align: left;
  }
  h3 {
    color:#fffffe;
    font-size: 1.5rem;
    font-weight: 600;
  }
  h5 {
    color:#fffffe;
    font-size: 1rem;
    font-weight: 400;
  }
  .custom-card {
    background-image: url('@/assets/wap/background1.jpg');
    background-size: cover !important;
    background-position: center;
    color: #fffffe;
  }
</style>