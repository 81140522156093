import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from '../views/ContactView.vue'
import EducationView from '../views/EducationView.vue'
import TrainingView from '../views/TrainingView.vue'
import EmploymentView from '../views/EmploymentView.vue'
import StudentView from '../views/StudentView.vue'
import RtoView from '../views/RtoView.vue'
import CourseView from '../views/CourseView.vue'
import CourseDetailView from '../views/CourseDetailView.vue'
import CookView from '../views/CookView.vue'
import NurseView from '../views/NurseView.vue'
import TrainingBriefView from '../views/TrainingBriefView.vue'
import StaffPortalView from '../views/StaffPortalView.vue'
import EventView from '../views/EventView.vue'
import AboutView from '../views/AboutView.vue'

import WapHomeView from '../views/wap/WapHomeView.vue'
import WapEducationView from '../views/wap/WapEducationView.vue'
import WapStudentView from '../views/wap/WapStudentView.vue'
import WapRtoView from '../views/wap/WapRtoView.vue'
import WapEventView from '../views/wap/WapEventView.vue'
import WapTrainingView from '../views/wap/WapTrainingView.vue'
import WapTrainingBriefView from '../views/wap/WapTrainingBriefView.vue'
import WapNurseView from '../views/wap/WapNurseView.vue'
import WapCookView from '../views/wap/WapCookView.vue'
import WapEmploymentView from '../views/wap/WapEmploymentView.vue'
import WapContactView from '../views/wap/WapContactView.vue'
import WapAboutView from '../views/wap/WapAboutView.vue'
import WapStaffPortalView from '../views/wap/WapStaffPortalView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    children:[
      {path: ':tab',component: ContactView}
    ]
  },
  {
    path: '/education',
    name: 'education',
    component: EducationView
  },
  {
    path: '/training',
    name: 'training',
    component: TrainingView
  },
  {
    path: '/employment',
    name: 'employment',
    component: EmploymentView
  },
  {
    path: '/staff',
    name: 'staff',
    component: StaffPortalView
  },
  {
    path: '/student',
    name: 'student',
    component: StudentView
  },
  {
    path: '/rto',
    name: 'rto',
    component: RtoView
  },
  {
    path: '/course',
    name: 'course',
    component: CourseView
  },
  {
    path: '/course-detail/:id',
    name: 'courseDetail',
    component: CourseDetailView
  },
  {
    path: '/cook',
    name: 'cook',
    component: CookView
  },
  {
    path: '/nurse',
    name: 'nurse',
    component: NurseView
  },
  {
    path: '/training-brief',
    name: 'trainingBrief',
    component: TrainingBriefView
  },
  {
    path: '/event',
    name: 'event',
    component: EventView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/-wap',
    redirect: '/home-wap'
  },
  {
    path: '/home-wap',
    name: 'home-wap',
    component: WapHomeView
  },
  {
    path: '/education-wap',
    name: 'education-wap',
    component: WapEducationView
  },
  {
    path: '/student-wap',
    name: 'student-wap',
    component: WapStudentView
  },
  {
    path: '/rto-wap',
    name: 'rto-wap',
    component: WapRtoView
  },
  {
    path: '/event-wap',
    name: 'event-wap',
    component: WapEventView
  },
  {
    path: '/training-wap',
    name: 'training-wap',
    component: WapTrainingView
  },
  {
    path: '/training-brief-wap',
    name: 'training-brief-wap',
    component: WapTrainingBriefView
  },
  {
    path: '/nurse-wap',
    name: 'nurse-wap',
    component: WapNurseView
  },
  {
    path: '/cook-wap',
    name: 'cook-wap',
    component: WapCookView
  },
  {
    path: '/employment-wap',
    name: 'employment-wap',
    component: WapEmploymentView
  },
  {
    path: '/contact-wap',
    name: 'contact-wap',
    component: WapContactView,
    children:[
      {path: ':tab',component: WapContactView}
    ]
  },
  {
    path: '/about-wap',
    name: 'about-wap',
    component: WapAboutView
  },
  {
    path: '/staff-wap',
    name: 'staff-wap',
    component: WapStaffPortalView
  }
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
