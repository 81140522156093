<template>
    <div class="contact">
        <h1>CONTACT US</h1>
        <div style="padding: 0 2vh;">
            <el-page-header @back="onBack"></el-page-header>
        </div>
        <div style="padding: 0 2vh;">
            <br>
            <button class="button-23" @click="redirect">Click Here To Start Your Application</button>
            <CardText :value="data.paragraph"></CardText>
        </div>
        <!-- <div class="flex">
          <div style="flex: 1;" />
          <div style="flex: 8;">
            <el-page-header @back="onBack"></el-page-header>
          </div>
          <div style="flex: 10;"></div>
        </div> -->
        
        <!-- <div class="flex">
  
          <div style="flex: 1;" />
  
          <div style="flex: 8;">
            <CardText :value="data.paragraph"></CardText>
          </div>
  
          <div style="flex: 1;" />
  
          <div style="flex: 8;">
            <h5 class="line-with-text">
              <el-divider class="divider" border-style="double" />
              Contact Form
              <el-divider class="divider" border-style="double" />
            </h5>
            <el-tabs v-model="activeTab" class="demo-tabs" @tab-click="handleClick">
              <el-tab-pane label="Student" name="student"><StudentForm></StudentForm></el-tab-pane>
              <el-tab-pane label="RTO Partners" name="rto"><RtoForm></RtoForm></el-tab-pane>
              <el-tab-pane label="Training" name="training"><TrainingForm></TrainingForm></el-tab-pane>
            </el-tabs>
          </div>
  
          <div style="flex: 1;" />
        </div> -->
        
        <br>
    </div>
  </template>

<script lang="ts" setup>
    import { ref, reactive, onMounted } from 'vue'
    import type { TabsPaneContext } from 'element-plus'
    import { useRoute, useRouter } from 'vue-router'

    import CardText from '@/components/CardText.vue'
    // import StudentForm from '@/components/StudentForm.vue'
    // import RtoForm from '@/components/RtoForm.vue'
    // import TrainingForm from '@/components/TrainingForm.vue'

    const route = useRoute();
    const router = useRouter();

    const redirect = () => {
      window.location.href = "https://gepgrp.com/Geppathway/maintenance/application_form";
    }

    // const activeTab = typeof route.params.tab === 'string' ? route.params.tab : 'student';

    const handleClick = (tab: TabsPaneContext, event: Event) => {
    // console.log(tab, event)
    }

    const onBack = () => {
        router.back();
        // notify('Back')
    }

    const paragraph = [
    {
        title:'Head Office',
        data:'801 Glenferrie Road<br>Hawthorn VIC 3122<br>AUSTRALIA<br>WEBSITE: www.gepgrp.com<br>Email: Info@gepgrp.com'
    },
    {
        title:'Philippines',
        data:'Unit 2306A Centuria Medical Makati<br>Crn Salamanca and Kalayaan Sts<br>Barangay Poblacion, Makati City 1210'
    },
    {
        title:'Cameroon',
        data:'Global Business Group<br>Carrefour Roi<br>Belle, Bonaberi<br>Douala, Cameroon<br>'
    },
    {
        title:'Iran',
        data:'Gohar Daanesh Iranian<br>Mr. Nader Saeedi'
    },
    {
        title:'Saudi Arabia',
        data:'6843 Ismaeli Alakhbari<br>Aiajawad District<br>Jeddah 23466-3125<br>SAUDI ARABIA<br>Saeed Abdullah Alkhuraimi'
    }
    ];

    const data = reactive(
    {
        paragraph:paragraph
    }
    );


</script>

<style scoped>
.line-with-text {
      display: flex;
      align-items: center;
  }

  .divider {
      flex: 1;
      margin: 0 20px;
  }
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
</style>